import React from 'react'
import { Link } from 'gatsby'
import connectFacebook from '../../static/connect-facebook.svg'
import connectInsta from '../../static/connect-insta.svg'
import connectTwitter from '../../static/connect-twitter.svg'
import connectEmail from '../../static/connect-email.svg'
import footerStyles from './footerStyles.module.css'

const Footer = props => {
return (
  <div style={{background:'#333', padding:'10px'}}>
    <div style={{maxWidth:'1200px', margin:'0 auto'}}>
      <div className={footerStyles.footerWrap}>
        <div style={{textAlign:'center', padding:'10px'}}>
          <h4 style={{marginBottom:'15px'}}>CONTACT US</h4>
          <p>6th Floor, 2 London Wall Place London, EC2Y 5AU</p>
          <p style={{fontWeight:'900'}}>Editorial</p>
          <p>Phone: <a style={{color:'#ffffff'}} href='tel:+447494903458'>07494903458</a></p>
          <p>Email: <a href='mailto:editor@horburyossettnews.co.uk'>editor@horburyossettnews.co.uk</a></p>
          <p style={{fontWeight:'900'}}>Sales</p>
          <p>Phone: <a style={{color:'#ffffff'}} href='tel:+447494903458'>07494903458 </a></p>
          <p>Email: <a href='mailto:sales@hilltopsphoenix.com.au'>sales@horburyossettnews.co.uk</a></p>

        </div>
        <div className={footerStyles.contact} style={{textAlign:'center', padding:'10px'}} >
          <h4 style={{marginBottom:'15px'}}>SUBMIT AN ARTICLE</h4>
          <div style={{maxWidth:'250px', margin: '0 auto'}}>
          <p>We are always looking for new articles of interest to the local community.</p>
          <p>Please feel free to submit an article for possible inclusion in a future issue.</p>
          <p>To submit an article, <Link to={`/contact`}>click here to use our online article submission form.</Link></p>

          </div>
        </div>
        <div style={{textAlign:'center', padding:'10px'}} >
        <h4 style={{marginBottom:'15px'}} >FOLLOW US</h4>
          <div style={{display:'flex',  justifyContent: 'space-around', maxWidth: '175px', margin: '0 auto',}}>
            <a href="https://www.facebook.com/horburyossettnews/"><img style={{maxWidth:'35px'}} src={connectFacebook} alt="Horbury Osssett News Facebook"/></a>
            <a href="https://www.instagram.com/horburyossettnews/"><img style={{maxWidth:'35px'}} src={connectInsta} alt="Horbury Osssett News Instagram"/></a>
            <a href="https://twitter.com/HorburyOssett/"><img style={{maxWidth:'35px'}} src={connectTwitter} alt="Horbury Osssett News Twitter"/></a>
            <Link to="/subscribe/"><img style={{maxWidth:'35px'}} src={connectEmail} alt="Horbury Ossett News Subscribe"/></Link>
          </div>
        </div>
      </div>
    </div>
    <div style={{borderTop: '1px solid #666', fontSize:'12px', textAlign:'center', paddingTop:'10px'}}>
      <Link to={`/about-us`} style={{color:'#ffffff'}}>About Us</Link>
      <Link to={`/journalistic-ethics-and-complaints-policy`} style={{marginLeft:'15px', color:'#ffffff'}}>Journalistic Ethics and Complaints Policy</Link>
      <Link to={`/conflict-resolution`} style={{marginLeft:'15px', color:'#ffffff'}}>Conflict Resolution</Link>
      <Link to={`/privacy-policy`}style={{marginLeft:'15px', color:'#ffffff'}}>Privacy Policy</Link>
      <a style={{marginLeft:'15px', color:'#ffffff'}} href="https://www.horburyossettnews.co.uk//sitemap.xml">Site Map</a>
      <a style={{marginLeft:'15px', color:'#ffffff'}} href="https://www.horburyossettnews.co.uk//rss.xml">RSS Feed</a>
    </div>
  </div>
  )
}

export default Footer
